import { makeAutoObservable } from 'mobx';
import { differenceInDays, differenceInHours, isSameDay } from 'date-fns';
import {
  DonationCampaign,
  GetDonationCampaignMetricsResponse,
} from '@wix/ambassador-donations-v1-donation-campaign/types';
import { ILocaleKeys } from '@/locale-keys';

export type IGoalState = ReturnType<typeof GoalState>;

type CommonState = {
  campaign?: DonationCampaign;
  metrics?: GetDonationCampaignMetricsResponse;
};

export const GoalState = ($state: CommonState, localeKeys: ILocaleKeys) => {
  return makeAutoObservable({
    get deadline() {
      return $state.campaign?.campaignGoal?.deadline;
    },
    get isDeadlinePassed() {
      const { deadline } = this;
      return deadline ? deadline < new Date() : false;
    },
    get isTargetReached() {
      const { campaign, metrics } = $state;
      /* istanbul ignore if: edge-case, should call isTargetReached only when has goal */
      if (!campaign || !metrics) {
        return false;
      }
      return (
        Number(metrics.totalAmount?.amount ?? 0) >=
        Number(
          campaign.campaignGoal?.targetAmount?.amount ??
            /* istanbul ignore next: will never reach this case */ 0,
        )
      );
    },
    get numOfDaysLeft() {
      if (!this.deadline || this.isDeadlinePassed) {
        return 0;
      }
      return differenceInDays(this.deadline, new Date()) + 1;
    },
    get numOfHoursLeft() {
      if (!this.deadline || this.isDeadlinePassed) {
        return 0;
      }
      return differenceInHours(this.deadline, new Date()) + 1;
    },
    get timeLeftText() {
      if (this.deadline) {
        return isSameDay(this.deadline, new Date())
          ? localeKeys.donations.widget.progressBar.dynamicHoursLeft({
              numHours: this.numOfHoursLeft,
            } as any)
          : localeKeys.donations.widget.progressBar.dynamicDaysLeft({
              numDays: this.numOfDaysLeft,
            } as any);
      } else {
        return undefined;
      }
    },
    get targetAmount() {
      return Number($state.campaign?.campaignGoal?.targetAmount?.amount ?? 0);
    },
    get formattedTargetAmount() {
      return $state.campaign?.campaignGoal?.targetAmount?.formattedAmount;
    },
    get totalAmount() {
      return Number($state.metrics?.totalAmount?.amount ?? 0);
    },
    get formattedTotalAmount() {
      return $state.metrics?.totalAmount?.formattedAmount;
    },
    get donationCount() {
      return $state.metrics?.donationCount ?? 0;
    },
  });
};
