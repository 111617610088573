import { getInitialData } from './services/getInitialData';
import model from './model';
import { createStateWithInitialData } from './services/createStateWithInitialData';
import { ComponentIds, StateIds } from './constants';
import { initLocaleKeys } from '@/common/utils/locale';
import EmptyGoalState from './EmptyGoalState/EmptyGoalState.bind';
import ActiveGoalState from './ActiveGoalState/ActiveGoalState.bind';

export default model.createController(
  ({ flowAPI, $props, $widget, $bindAll, $w }) => {
    return {
      pageReady: async () => {
        const httpClient = flowAPI.essentials.httpClient;
        const localeKeys = initLocaleKeys(flowAPI.translations.i18n);
        const $state = await createStateWithInitialData({
          httpClient,
          $props,
          $widget,
          localeKeys,
        });
        const store = { $state, $w, localeKeys };

        const resetStateData = async () => {
          const updatedCampaignId =
            $props.campaignId ??
            /* istanbul ignore next: no way to mock */ $widget.props.campaignId;
          const updaedData = await getInitialData(
            httpClient,
            updatedCampaignId,
          );
          $state.reset(updaedData);
        };

        $widget.onPropsChanged(resetStateData);
        $bindAll({
          [ComponentIds.MultiStateBox]: {
            currentState: () =>
              $state.showEmptyState ? StateIds.Empty : StateIds.Active,
          },
          [ComponentIds.RootBox]: {
            deleted: () =>
              $state.showEmptyState && !flowAPI.environment.isEditor,
          },
          ...EmptyGoalState(store),
          ...ActiveGoalState(store),
        });
      },
      exports: {},
    };
  },
);
