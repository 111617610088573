import { IWixAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getLocale } from './locale';

const DEFAULT_CURRENCY = 'USD';

export const getCurrency = ({ wixCodeApi }: { wixCodeApi: IWixAPI }) => {
  return wixCodeApi.site.currency ?? DEFAULT_CURRENCY;
};

export const setupCurrencyFormatter = ({
  getCurrencyFormatterFromFlowAPI,
  wixCodeApi,
}: {
  getCurrencyFormatterFromFlowAPI: PlatformControllerFlowAPI['getCurrencyFormatter'];
  wixCodeApi: IWixAPI;
}) => {
  const locale = getLocale({ wixCodeApi });
  return getCurrencyFormatterFromFlowAPI({
    language: locale,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
};

export const getCurrencySymbol = (currency: string, language: string) =>
  new Intl.NumberFormat(language, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;
