import { makeAutoObservable } from 'mobx';
import { getInitialData } from './getInitialData';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  DonationCampaign,
  GetDonationCampaignMetricsResponse,
} from '@wix/ambassador-donations-v1-donation-campaign/types';
import { GoalState } from '@/common/GoalState';
import { ILocaleKeys } from '@/locale-keys';

export type StandaloneGoalState = {
  campaign: DonationCampaign | undefined;
  metrics: GetDonationCampaignMetricsResponse | undefined;
  goal: ReturnType<typeof GoalState>;
};

export const createStateWithInitialData = async ({
  $props,
  $widget,
  httpClient,
  localeKeys,
}: {
  $props: { campaignId: string };
  $widget: { props: { campaignId: string } };
  httpClient: IHttpClient;
  localeKeys: ILocaleKeys;
}) => {
  const campaignId = $props.campaignId ?? $widget.props.campaignId;
  const initialData = await getInitialData(httpClient, campaignId);
  return new State(initialData, localeKeys);
};

class State implements StandaloneGoalState {
  campaign: DonationCampaign | undefined;
  metrics: GetDonationCampaignMetricsResponse | undefined;
  public goal = GoalState(this, this.localeKeys);
  constructor(
    initialData: Pick<StandaloneGoalState, 'campaign' | 'metrics'>,
    private localeKeys: ILocaleKeys,
  ) {
    this.campaign = initialData.campaign;
    this.metrics = initialData.metrics;
    makeAutoObservable(this, {
      goal: false,
    });
  }

  get showEmptyState() {
    return !this.campaign || !this.campaign.campaignGoalEnabled;
  }

  reset(data: Pick<StandaloneGoalState, 'campaign' | 'metrics'>) {
    this.campaign = data.campaign;
    this.metrics = data.metrics;
  }
}
