import { AmountOption } from '@/common/types';
import { SuggestedAmount } from '@wix/ambassador-donations-v1-donation-campaign/types';

export const suggestedAmountToOption = ({
  amount,
  description,
}: SuggestedAmount): AmountOption => ({
  value: `${amount?.amount}`,
  amountLabel: `${amount?.formattedAmount}`,
  label: `${amount?.formattedAmount}`,
  amountImpact: description,
});
